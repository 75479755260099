import React, { useState, useEffect } from 'react';
import { userIsLoggedInLanding } from '@/utils/user';
import Link from 'next/link';
import { SignFrom } from '@/components/Sign';


type MenuProps = {
    signValue: string;
    setSignValue: (value: string) => void;
    toggleSignForm: boolean;
    setToggleSignForm: (value: boolean) => void;
};

const Menu: React.FC<MenuProps> = ({ signValue, setSignValue, toggleSignForm, setToggleSignForm }) => {
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
    const [isClosed, setIsClosed] = useState<boolean>(true);

    const checkIfuserLoggedIn = async () => {
        let isLoggedIn = await userIsLoggedInLanding();
        setIsLoggedIn(isLoggedIn);
    }

    useEffect(() => {
        checkIfuserLoggedIn()
    }, [])

    return (
        <div className="w-full justify-center flex fixed top-0 z-20">
            <div
                className={isClosed ?
                    "flex h-16 sm:justify-between gap-6 sm:space-between relative z-[9999] py-4 pl-4 sm:pl-8 pr-2 mt-[24px] max-w-[1350px] w-[90%] top-0 rounded-full backdrop-blur-xl bg-white bg-opacity-10 border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)]"
                    :
                    "flex h-auto sm:justify-between sm:gap-6 sm:space-between relative z-[9999] py-4 pl-4 sm:pl-8 pr-2 mt-[24px] max-w-[1350px] w-[90%] top-0 rounded-3xl backdrop-blur-xl bg-white bg-opacity-10 border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)]"} >

                <button onClick={() => setIsClosed(!isClosed)} className='overflow-hidden  w-8 h-8 absolute top-4 right-4 z-[10] sm:hidden flex justify-center items-center rounded-full backdrop-blur-md  border-custom shadow-[0_4px_12px_0_rgba(0,0,0,0.07),_0_2px_4px_rgba(0,0,0,0.05)] hover:border hover:border-white transition'>
                    {
                        isClosed ?

                            <svg width={26} height={26} fill="none" stroke="currentColor" strokeLinecap="round" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.75 7.5h16.5" />
                                <path d="M3.75 12h16.5" />
                                <path d="M3.75 16.5h16.5" />
                            </svg>
                            :
                            <svg width={36} height={36} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path d="M17.25 17.25 6.75 6.75" />
                                <path d="m17.25 6.75-10.5 10.5" />
                            </svg>
                    }
                </button>
                <Link
                    className='h-full flex items-center sm:block sm:h-auto'
                    data-te-sidenav-link-ref
                    href="/#landing">
                    <img className='object-cover sm:h-14 sm:w-14 sm:-mt-4 w-12 h-12 sm:relative absolute top-1 left-4 sm:top-0 sm:left-0' src="/img/logoIAssistant.svg" alt="iassistant logo" />

                </Link>
                {
                    isClosed ? <>

                        <div className="sm:flex hidden gap-4 justify-start items-center flex-1">
                            <Link
                                className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                data-te-sidenav-link-ref
                                href="/#tools"
                            >
                                <span className='hover:text-slate-300 transition'>
                                    Outils IA
                                </span>
                            </Link>
                            <Link
                                className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                data-te-sidenav-link-ref
                                href="/#price">
                                <span className='hover:text-slate-300 transition'>
                                    Prix
                                </span>
                            </Link>
                            <Link
                                className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                data-te-sidenav-link-ref
                                href="/#about">
                                <span className='hover:text-slate-300 transition'>
                                    A propos
                                </span>
                            </Link>
                            <Link
                                className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                data-te-sidenav-link-ref
                                href="/blog">
                                <span className='hover:text-slate-300 transition'>
                                    Blog
                                </span>
                            </Link>
                        </div>
                        <div className='sm:flex hidden h-full justify-center items-center'>
                            {
                                isLoggedIn ?

                                    <Link
                                        className='z-10 border border-[#E17DC2]  hover:bg-[#E17DC2] transition rounded-full h-12 px-5 font-medium text-l  justify-center items-center flex'
                                        data-te-sidenav-link-ref
                                        href="/dashboard">
                                        <span>
                                            Tableau de bord
                                        </span>
                                    </Link>


                                    :
                                    <div className="flex gap-4">
                                        <button onClick={() => { setToggleSignForm(true); setSignValue("signin") }}
                                            className='z-10 border border-[#E17DC2]  hover:bg-[#E17DC2] transition rounded-full h-12 px-5 font-medium text-l  justify-center items-center flex' data-te-sidenav-link-ref>
                                            <span>
                                                Connection
                                            </span>
                                        </button>
                                        <button onClick={() => { setToggleSignForm(true); setSignValue("signup"); }}
                                            className='z-10 border border-[#E17DC2] bg-[#E17DC2] transition hover:bg-[#e08fc8] text-white rounded-full h-12 pl-5 pr-2 font-medium text-l  justify-center items-center flex'
                                            data-te-sidenav-link-ref>
                                            <span>
                                                Essai gratuit
                                            </span>
                                            <svg width={36} height={36} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                <path d="m9 6 6 6-6 6" />
                                            </svg>
                                        </button>
                                    </div>
                            }
                        </div></>
                        :
                        <div className='flex flex-col gap-4 w-full sm:w-[70%]'>
                            <div className="flex  flex-col  sm:hidden gap-4 justify-start items-center flex-1">
                                <Link
                                    className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                    data-te-sidenav-link-ref
                                    href="/#tools"
                                    onClick={(e) => {
                                        setIsClosed(true)
                                    }}>
                                    <span className='hover:text-slate-300 transition'>
                                        Outils IA
                                    </span>
                                </Link>
                                <Link
                                    className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                    data-te-sidenav-link-ref
                                    href="/#price"
                                    onClick={(e) => {
                                        setIsClosed(true)
                                    }}>
                                    <span className='hover:text-slate-300 transition'>
                                        Prix
                                    </span>
                                </Link>
                                <Link
                                    className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                    data-te-sidenav-link-ref
                                    href="/#about"
                                    onClick={(e) => {
                                        setIsClosed(true)
                                    }}>
                                    <span className='hover:text-slate-300 transition'>
                                        A propos
                                    </span>
                                </Link>
                                <Link
                                    className='z-10 py-2 px-4 font-medium text-xl  justify-center items-center flex'
                                    data-te-sidenav-link-ref
                                    href="/blog"
                                    onClick={(e) => {
                                        setIsClosed(true)
                                    }}>
                                    <span className='hover:text-slate-300 transition'>
                                        Blog
                                    </span>
                                </Link>
                            </div>
                            <div className='flex sm:hidden h-full justify-center items-center'>
                                {
                                    isLoggedIn ?

                                        <Link
                                            className='z-10 border border-[#E17DC2]  hover:bg-[#E17DC2] transition rounded-full h-12 px-5 font-medium text-l  justify-center items-center flex'
                                            data-te-sidenav-link-ref
                                            href="/dashboard">
                                            <span>
                                                Tableau de bord
                                            </span>
                                        </Link>
                                        :
                                        <div className="flex gap-4 sm:flex-row flex-col">
                                            <button onClick={() => { setIsClosed(true); setToggleSignForm(true); setSignValue("signin") }}
                                                className='z-10 border border-[#E17DC2]  hover:bg-[#E17DC2] transition rounded-full h-12 px-5 font-medium text-l  justify-center items-center flex' data-te-sidenav-link-ref>
                                                <span>
                                                    Connection
                                                </span>
                                            </button>
                                            <button onClick={() => { setIsClosed(true); setToggleSignForm(true); setSignValue("signup") }}
                                                className='z-10 border border-[#E17DC2] bg-[#E17DC2] transition hover:bg-[#e08fc8] text-white rounded-full h-12 pl-5 pr-2 font-medium text-l  justify-center items-center flex'
                                                data-te-sidenav-link-ref>
                                                <span>
                                                    Essai gratuit
                                                </span>
                                                <svg width={36} height={36} fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="m9 6 6 6-6 6" />
                                                </svg>
                                            </button>
                                        </div>
                                }
                            </div>
                        </div>
                }
            </div>
        </div >
    );
};

export default Menu;



