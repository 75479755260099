import React, { useState } from 'react';
import Link from 'next/link';
import Image from 'next/image'
const Footer: React.FC = () => {

    return (
        <footer className='flex justify-center flex-col items-center gap-4 h-full sm:h-[450px] pt-16 w-full'>
            <div className="w-3/5 justify-start flex items-center gap-2">
                <Image className='w-[300px] flex sm:justify-center' src="/img/landing/logofull.svg" alt="eurekia logo" width={150}
                    height={150} />
                <p className="text-slate-200 border-l pl-4 text-xl hidden sm:flex">
                    Toutes l'IA, au même endroit.
                </p>
            </div>
            <div className="w-4/5 sm:w-3/5 border-t border-slate-400 py-16 flex">

                <div className="flex flex-col gap-2 items-start sm:px-4 sm:w-1/4 w-1/2">
                    <p className="font-bold text-xl ">
                        Produit
                    </p>
                    <Link
                        className='z-10 py-2 font-medium text-lg  justify-center items-center flex'
                        data-te-sidenav-link-ref
                        href="/#tools"
                    >
                        <span className='hover:text-slate-300 transition'>
                            Outils IA
                        </span>
                    </Link>
                    <Link
                        className='z-10 py-2 font-medium text-lg  justify-center items-center flex'
                        data-te-sidenav-link-ref
                        href="/#price">
                        <span className='hover:text-slate-300 transition'>
                            Prix
                        </span>
                    </Link>
                    <Link
                        className='z-10 py-2 font-medium text-lg  justify-center items-center flex'
                        data-te-sidenav-link-ref
                        href="/#about">
                        <span className='hover:text-slate-300 transition'>
                            A propos
                        </span>
                    </Link>
                </div>
                <div className="flex flex-col gap-2 items-start sm:px-4 sm:w-1/4 w-1/2">
                    <p className="font-bold text-xl ">
                        Contact
                    </p>
                    <Link
                        className='z-10 py-2 font-medium text-lg  justify-center items-center flex'
                        data-te-sidenav-link-ref
                        href="/contact"
                    >
                        <span className='hover:text-slate-300 transition'>
                            Contact
                        </span>
                    </Link>
                    <Link
                        className='z-10 py-2 font-medium text-lg  justify-center items-center flex'
                        data-te-sidenav-link-ref
                        href="/faq"
                    >
                        <span className='hover:text-slate-300 transition'>
                            FAQ
                        </span>
                    </Link>
                </div>
            </div>
            <div className=' flex w-full justify-center text-slate-300'>
                <p className="text-center pb-4">©2023 Eurekia Tous droits réservés. | <Link data-te-sidenav-link-ref href="/usage" className='hover:text-white2 transition'>Conditions d'Utilisation</Link> | <Link data-te-sidenav-link-ref href="/confidencials-policies" className='hover:text-white2 transition'>Politique de Confidentialité</Link> | <Link data-te-sidenav-link-ref href="/legals" className='hover:text-white2 transition'>Mentions Légales</Link></p>


            </div>

        </footer>
    );
};

export default Footer;
