import React, { useState, useEffect } from 'react';
import { Button } from "@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card"
import { Input } from "@/components/ui/input"
import { Label } from "@/components/ui/label"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs"
import { SignIn, SignUp, PasswordRecovery } from '@/utils/signFunctions';
import { useRouter } from 'next/navigation'
import { SignObj } from '@/types/database_types';
// import { supabase } from '@/utils/clientSupabase';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { createClientComponentClient } from "@supabase/auth-helpers-nextjs";
import { usePlausible } from 'next-plausible'

const supabase = createClientComponentClient()

type SignFromProps = {
    signValue: string;
};

export const SignFrom: React.FC<SignFromProps> = ({ signValue }) => {
    const plausible = usePlausible()
    const [message, setMessage] = useState<string>('');
    const [messageError, setMessageError] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [recoverPassword, setrecoverPassword] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [toggleVerifyEmail, setToggleVerifyEmail] = useState<boolean>(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [isPasswordVisible2, setIsPasswordVisible2] = useState<boolean>(false);
    const [passwordConfirmation, setPasswordConfirmation] = useState<string>('');
    const [valueChange, setValueChange] = useState<number>(1);

    let router = useRouter()

    useEffect(() => {
        const button = document.querySelector('.supabase-auth-ui_ui-button');
        if (button) {
            const textNode = Array.from(button.childNodes).find(node => node.nodeType === Node.TEXT_NODE);
            if (textNode) {
                textNode.nodeValue = "Se connecter avec Google";
            }
        }
    }, [valueChange]);

    const handleSignUp = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessageError('')
        const request: SignObj = { email, password };
        setIsLoading(true)
        if (password === passwordConfirmation) {
            try {
                const result = await SignUp(request);
                console.log("result", result);
                if (result.emailIsTakenMessage) {
                    setIsLoading(false)
                    setMessageError(result.emailIsTakenMessage)
                    plausible('Inscription')
                    return
                }
                if (result.data.user.aud === "authenticated") {
                    console.log("🚀 ~ file: Sign.tsx:62 ~ handleSignUp ~ result.data.user.aud:", result.data.user.aud)
                    console.log("🚀 ~ file: Sign.tsx:62 ~ handleSignUp ~ result.data.user.aud:")

                    setToggleVerifyEmail(true)
                    setIsLoading(false)
                    //setMessage('Merci pour votre inscription ! Veuillez confirmer votre adresse email.')
                }

            } catch (error) {
                console.error(error);
                setIsLoading(false)
                setMessageError('Une erreur est survenue.')
            }
        } else {
            setIsLoading(false)
            setMessageError('Les mots de passes ne correspondent pas.');
            return;
        }
    };

    const handleSignIn = async (e: React.FormEvent) => {
        e.preventDefault();
        setMessageError('')
        const request: SignObj = { email, password };
        setIsLoading(true)
        try {
            const result = await SignIn(request);
            console.log(result);
            if (result.data.user.aud === "authenticated") {
                router.push('/dashboard')
            }

        } catch (error) {
            setIsLoading(false)
            console.error(error);
            setMessageError('Votre identifiant ou votre mot de passe est incorrect.')
        }

    };

    const handleRecoverEmail = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsLoading(true)
        const request: SignObj = { email, password };
        try {
            if (request.email) {
                const result = await PasswordRecovery(request.email);
                console.log(result);
                if (!result.error) {
                    setIsLoading(false)
                    setMessage('Un email vous a été envoyé pour changer votre mot de passe')
                }
            } else {
                setIsLoading(false)
                console.error('request.email is undefined.');
            }

        } catch (error) {
            console.error(error);
            setIsLoading(false)
            setMessageError('Une erreur est survenue.')
        }
    };

    return (
        <>
            {
                toggleVerifyEmail ?
                    <div className='p-8  bg-black2/80 xl:w-2/3 rounded-xl flex flex-col gap-8 h-4/5 lg:px-8'>
                        <div className='flex flex-col justify-center items-center w-full'>
                            <p className='text-3xl text-white2 text-center font-semibold'>
                                Merci pour votre inscription !
                            </p>
                            <p className='text-3xl text-white2 text-center pb-8'>
                                Veuillez confirmer votre adresse email pour continuer.
                            </p>
                            <p className='text-xl italic text-white2 text-center'>
                                Vérifiez vos courriels indésirables
                            </p>
                        </div>
                    </div>
                    :
                    <>
                        {
                            recoverPassword ?
                                <div className="w-[95%] sm:w-[400px]">
                                    <div className='backdrop-blur bg-white bg-opacity-10 rounded-xl shadow-lg border-custom w-[95%] sm:w-[400px]'>
                                        <form onSubmit={handleRecoverEmail}>
                                            <Card>
                                                <CardHeader>
                                                    <CardTitle>Réinitialisez votre mot de passe</CardTitle>
                                                    <CardDescription>
                                                        Remplissez votre adresse e-mail pour rececoir un e-mail de récupération de mot de passe.
                                                    </CardDescription>
                                                </CardHeader>
                                                <CardContent className="space-y-2">
                                                    <div className="space-y-1">
                                                        <Label htmlFor="email">E-mail</Label>
                                                        <Input id="email" defaultValue="" type='email' onChange={(e) => setEmail(e.target.value)} required />
                                                    </div>
                                                </CardContent>
                                                <CardFooter>
                                                    <Button>
                                                        {
                                                            isLoading ?
                                                                <svg className="animate-spin h-5 w-5 text-black2 stroke-brand fill-brand" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="" strokeWidth="4"></circle>
                                                                    <path className="opacity-75" fill="" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                </svg>
                                                                :
                                                                <>Envoyer</>
                                                        }
                                                    </Button>
                                                </CardFooter>
                                                <p className='p-4 text-green-600'>{message}</p>
                                                <p className='p-4 text-red-600'>{messageError}</p>
                                            </Card>
                                        </form>
                                    </div>

                                </div>
                                :
                                <Tabs defaultValue={signValue} className="w-[95%] sm:w-[400px]">
                                    <TabsList onClick={() => setValueChange(valueChange + 1)} className="grid w-full grid-cols-2 backdrop-blur-md bg-white bg-opacity-10 shadow-lg border-custom">
                                        <TabsTrigger value="signin">Connexion</TabsTrigger>
                                        <TabsTrigger value="signup">Inscription</TabsTrigger>
                                    </TabsList>
                                    <TabsContent value="signin" className='backdrop-blur-md bg-white bg-opacity-10 rounded-xl shadow-lg border-custom'>
                                        <Card>
                                            <CardHeader>
                                                <CardTitle>Connexion</CardTitle>
                                                <Auth
                                                    supabaseClient={supabase}
                                                    appearance={{ theme: ThemeSupa }}
                                                    providers={["google"]}
                                                    queryParams={{
                                                        hd: 'https://www.eurekia.xyz/dashboard',
                                                    }}
                                                    onlyThirdPartyProviders
                                                />
                                                <CardDescription>
                                                    Remplissez le formulaire ci-dessous pour vous connecter.
                                                </CardDescription>
                                            </CardHeader>
                                            <form onSubmit={handleSignIn}>
                                                <CardContent className="space-y-2">
                                                    <div className="space-y-1">
                                                        <Label htmlFor="email">E-mail</Label>
                                                        <Input id="email" defaultValue="" type='email' onChange={(e) => setEmail(e.target.value)} required />
                                                    </div>
                                                    <div className="space-y-1">
                                                        <Label htmlFor="current">Mot de passe</Label>
                                                        <div className='flex gap-2'>
                                                            <Input autoComplete='current-password' id="current" type={isPasswordVisible ? "text" : "password"} minLength={6} required onChange={(e) => setPassword(e.target.value)} />
                                                            <div
                                                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                                                className='flex justify-center items-center cursor-pointer'>
                                                                {
                                                                    isPasswordVisible ?
                                                                        <svg width="26" height="26" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94"></path>
                                                                            <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24"></path>
                                                                            <path d="m1 1 22 22"></path>
                                                                            <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19"></path>
                                                                        </svg>
                                                                        :
                                                                        <svg width="26" height="26" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                                            <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"></path>
                                                                        </svg>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                                <CardFooter>
                                                    <Button>
                                                        {
                                                            isLoading ?
                                                                <svg className="animate-spin h-5 w-5 text-black2 stroke-brand fill-brand" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="" strokeWidth="4"></circle>
                                                                    <path className="opacity-75" fill="" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                </svg>
                                                                :
                                                                <>Se connecter</>
                                                        }
                                                    </Button>
                                                </CardFooter>
                                                <p className='p-4 text-white'>Mot de passe oublié ? <button onClick={() => { setMessageError(""); setrecoverPassword(true) }} className='bold underline'>Réinitialisez votre mot de passe.</button></p>
                                                <p className='p-4 text-green-600'>{message}</p>
                                                <p className='p-4 text-red-600'>{messageError}</p>
                                            </form>
                                        </Card>
                                    </TabsContent>
                                    <TabsContent value="signup" className='backdrop-blur-md bg-white bg-opacity-10 rounded-xl shadow-lg border-custom'>
                                        <Card>
                                            <CardHeader>
                                                <CardTitle>Inscription</CardTitle>
                                                <Auth
                                                    supabaseClient={supabase}
                                                    appearance={{ theme: ThemeSupa }}
                                                    providers={["google"]}
                                                    queryParams={{
                                                        hd: 'https://www.eurekia.xyz/dashboard',
                                                    }}
                                                    onlyThirdPartyProviders
                                                />
                                                <CardDescription>
                                                    Remplissez le formulaire ci-dessous pour vous inscrire.
                                                </CardDescription>
                                            </CardHeader>
                                            <form onSubmit={handleSignUp}>
                                                <CardContent className="space-y-2">
                                                    <div className="space-y-1">
                                                        <Label htmlFor="current">Adresse e-mail</Label>
                                                        <Input id="current" type="email" onChange={(e) => setEmail(e.target.value)} required />
                                                    </div>

                                                    <div className="space-y-1">
                                                        <Label htmlFor="current">Mot de passe</Label>
                                                        <div className='flex gap-2'>
                                                            <Input autoComplete='current-password' id="current" type={isPasswordVisible ? "text" : "password"} minLength={6} required onChange={(e) => setPassword(e.target.value)} />
                                                            <div
                                                                onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                                                                className='flex justify-center items-center cursor-pointer'>
                                                                {
                                                                    isPasswordVisible ?
                                                                        <svg width="26" height="26" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94"></path>
                                                                            <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24"></path>
                                                                            <path d="m1 1 22 22"></path>
                                                                            <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19"></path>
                                                                        </svg>
                                                                        :
                                                                        <svg width="26" height="26" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                                            <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"></path>
                                                                        </svg>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="space-y-1">
                                                        <Label htmlFor="current">Confirmer votre mot de passe</Label>
                                                        <div className='flex gap-2'>
                                                            <Input autoComplete='current-password' id="current" type={isPasswordVisible2 ? "text" : "password"} minLength={6} required onChange={(e) => setPasswordConfirmation(e.target.value)} />
                                                            <div
                                                                onClick={() => setIsPasswordVisible2(!isPasswordVisible2)}
                                                                className='flex justify-center items-center cursor-pointer'>
                                                                {
                                                                    isPasswordVisible2 ?
                                                                        <svg width="26" height="26" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94"></path>
                                                                            <path d="M14.12 14.12a3 3 0 1 1-4.24-4.24"></path>
                                                                            <path d="m1 1 22 22"></path>
                                                                            <path d="M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19"></path>
                                                                        </svg>
                                                                        :
                                                                        <svg width="26" height="26" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                                                                            <path d="M12 9a3 3 0 1 0 0 6 3 3 0 1 0 0-6z"></path>
                                                                        </svg>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </CardContent>
                                                <CardFooter>
                                                    <Button type="submit">
                                                        {
                                                            isLoading ?
                                                                <svg className="animate-spin h-5 w-5 text-black2 stroke-brand fill-brand" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="" strokeWidth="4"></circle>
                                                                    <path className="opacity-75" fill="" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                </svg>
                                                                :
                                                                <>S&apos;inscrire</>
                                                        }
                                                    </Button>
                                                </CardFooter>
                                                <p className='p-4 text-green-600'>{message}</p>
                                                <p className='p-4 text-red-600'>{messageError}</p>
                                            </form>
                                        </Card>
                                    </TabsContent>
                                </Tabs>

                        }
                    </>
            }
        </>
    )
}
